



































import {Vue, Component, Prop} from 'vue-property-decorator';
import {ServiceRecordDTO} from "@/components/payroll/business/services/ServiceDTO";
import moment from "moment/moment";
import ContractorUpdateServiceLogModal
	from "@/components/payroll/business/services/ContractorUpdateServiceLogModal.vue";
import Application from "@/state/Application";
import {EMPTY_PAGE} from "@/dto/taxation/Page";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import ServiceLogOrchestrator from "@/services/ServiceLogOrchestrator";
import {processError} from "@/utils/ComponentUtils";
import EmployerUpdateServiceLogModal from "@/components/payroll/business/services/EmployerUpdateServiceLogModal.vue";

export type ServiceLogViewSide = "CONTRACTOR" | "EMPLOYER"
type Mode = "SHORT" | "FULL";

@Component({
	computed: {
		moment() {
			return moment
		}
	}
})
export default class ServiceLogView extends Vue {

	@Prop()
	private log!: ServiceRecordDTO

	@Prop()
	private side!: ServiceLogViewSide

	private mode: Mode = "SHORT"

	@Prop()
	private preDisable!: (record: ServiceRecordDTO) => void;

	@Prop()
	private callback!: () => void

	toEdit() {
		this.preDisable(this.log)
		if (this.side == "CONTRACTOR") {
			this.$modal.show(
				ContractorUpdateServiceLogModal,
				{
					serviceLog: this.log,
					callback: this.callback
				},
				{
					classes: ["overflow-visible"]
				}
			)
		} else {
			this.$modal.show(
				EmployerUpdateServiceLogModal,
				{
					serviceLog: this.log,
					callback: this.callback
				},
				{
					classes: ["overflow-visible"]
				}
			)
		}
	}

	disable() {
		this.preDisable(this.log)
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: this.log.name,
				operationName: "disable",
				onDelete: () => {
					Application.startLoading()
					ServiceLogOrchestrator.disableLog(this.log.id, this.log.assignee.id).then(
						() => {
							Application.stopLoading()
							this.callback()
						},
						err => processError(err, this)
					)
				}
			}, {
				classes: ["zIndexTop"]
			}
		)
	}
}
