









































































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {
	BranchLocationOption,
	CreateServiceRecordPayload,
	LocationOption, ProjectLocationOption,
	ServiceDTO, ServiceFilter
} from "@/components/payroll/business/services/ServiceDTO";
import SelectOption from "@/components/common/SelectOption";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import {ifValid, processError} from "@/utils/ComponentUtils";
import ServiceOrchestrator from "@/services/ServiceOrchestrator";
import CompanyService from "@/services/CompanyService";
import Application from "@/state/Application";
import ServiceRecordOrchestrator from "@/services/ServiceLogOrchestrator";
import PortalInput from "@/components/common/PortalInput.vue";

@Component({
	components: {PortalInput, PortalDate, PortalTextarea, PortalSelect}
})
export default class CreationEmployerServiceLogModal extends Vue {

	private selectedLocation: LocationOption | null = null;
	private selectedService: ServiceDTO | null = null;
	private locationOptions: Array<SelectOption> = [];
	private selectedCustomer: IEmployment | null = null
	private contractors: Array<IEmployment> = []
	private services: Array<ServiceDTO> = [];
	private req = new CreateServiceRecordPayload()

	@Prop()
	private callback!: () => void

	selected() {
		this.selectedLocation!.selected(this.req)
	}

	selectService() {
		this.req.serviceId = this.selectedService!.id
		this.req.coefficient = this.selectedService!.coefficient
	}

	get customerOptions(): Array<SelectOption> {
		return this.contractors.map(c => SelectOption.builder().title(c.details.name).value(c).build())
	}

	get serviceOptions(): Array<SelectOption> {
		return this.services.map(s => SelectOption.builder().title(s.name + ' ' + s.formattedPrice).value(s).build())
	}

	get rxLocationOptions(): Array<SelectOption> {
		return this.locationOptions
	}

	private loadEmployments() {
		if (this.contractors.length > 0) return Promise.resolve()
		return EmploymentService.getAllByFilter(
			new EmploymentFilter(
				{
					employerId: this.$wss.getCurrent.id,
					status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS],
				}
			)
		).then(
			res => {
				if (res.data.length > 0) {
					this.req.assigneeId = res.data[0].id;
				}
				this.contractors = res.data;
			},
			err => processError(err, this)
		)
	}

	private async loadServices(): Promise<void> {
		if (!this.selectedCustomer) return;
		return ServiceOrchestrator.getAllByFilter(
			new ServiceFilter(
				{
					companyId: this.selectedCustomer!.employer.id,
					active: true
				}
			)
		).then(
			res => {
				this.services = res.data;
			},
			err => processError(err, this)
		)
	}

	private loadLocations() {
		if (!this.selectedCustomer) return;
		return Promise.all(
			[
				CompanyService.getProjectsByCompanyId(this.selectedCustomer!.employer.id).then(
					res => {
						if (res.data.length > 0) {
							this.locationOptions.push(...res.data.filter(it => it.address || this.selectedService?.salable).map(it =>
								SelectOption.builder()
									.title(it.title!)
									.value(ProjectLocationOption.of(it.id!))
									.build())
							)
						}
					}
				),
				CompanyService.getBranches(this.selectedCustomer!.employer.id).then(
					res => {
						if (res.data.length > 0) {
							this.locationOptions.push(...res.data.filter(it => it.address || this.selectedService?.salable).map(it =>
								SelectOption.builder()
									.title(it.title!)
									.value(BranchLocationOption.of(it.id!))
									.build())
							)
						}
					}
				),
				CompanyService.getHeadQuarter(this.selectedCustomer!.employer.id).then(
					res => {
						if (res.data) {
							this.locationOptions.push(
								SelectOption.builder()
									.title(res.data.title!)
									.value(BranchLocationOption.of(res.data.id!))
									.build()
							)
						}
					}
				)
			]
		).then(
			() => {return;},
			err => processError(err, this)
		)
	}

	private loadData() {
		Application.startLoading();
		this.loadEmployments()
			.then(() => this.loadServices())
			.then(() => this.loadLocations())
			.finally(() => Application.stopLoading())
	}

	mounted() {
		this.loadData()
	}

	save() {
		ifValid(this, () => {
			Application.startLoading()
			ServiceRecordOrchestrator.createLog(this.req).then(
				() => {
					this.$modal.hideAll()
					Application.stopLoading()
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

}
