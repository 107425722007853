import CompanyDTO from "@/dto/company/CompanyDTO";
import {Filter} from "@/dto/Pageable";
import {IEmployment} from "@/dto/payroll/IEmployment";
import InvoiceDTO from "@/dto/InvoiceDTO";

export interface ServiceDTO {
    id: number;
    company: CompanyDTO;
    name: string;
    description?: string;
    price: string;
    formattedPrice: string;
    salable: boolean;
    schedule: boolean;
    salesRate?: number;
    active: boolean;
    coefficient: string | null
}

export class ServiceFilter implements Filter{
    companyId: number | null = null;
    name: string | null = null;
    salable: boolean | null = null;
    schedule: boolean | null = null;
    active: boolean | null = null;
    page = 0;
    size = 20;

    constructor(init?: Partial<ServiceFilter>) {
        Object.assign(this, init);
    }
}

export class CreateServicePayload {
    companyId: number | null = null;
    name: string | null = null;
    description: string | null = null;
    price: number | null = null;
    sales = false;
    scheduled = false;
    coefficient: number | null = null;

    constructor(init?: Partial<CreateServicePayload>) {
        Object.assign(this, init);
    }
}

export class UpdateServicePayload {
    serviceId: number | null = null;
    companyId: number | null = null;
    name: string | null = null;
    description: string | null = null;
    price: string | null = null;

    constructor(init?: Partial<UpdateServicePayload>) {
        Object.assign(this, init);
    }
}

export class ServiceRecordDTO {
    id!: number;
    name!: string;
    description?: string;
    price!: number;
    formattedPrice!: string;
    coefficient?: number;
    assignee!: IEmployment;
    location!: string;
    state!: string;
    salable!: boolean;
    schedule!: boolean;
    salesRate!: number;
    active!: boolean;
    comment: string | null = null;
    startDate: string | null = null
    endDate: string | null = null;
    formattedCoefficient?: string;
    total!: string;
    formattedSalesRate!: string;
}

export class ServiceRecordFilter {
    serviceId: number | null = null;
    location: string | null = null;
    active: boolean | null = null;
    startDate: Date | string | null = null;
    endDate: Date | string | null = null
    assigneesId: number[] = [];
    state: string | null = null;
    employerId: number | null = null;
    page = 0;
    size = 20;

    constructor(init?: Partial<ServiceRecordFilter>) {
        Object.assign(this, init);
    }
}

export class UpdateServiceRecordPayload {
    serviceId: number | null = null;
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    branchId: number | null = null;
    projectId: number | null = null;
    assigneeId: number | null = null;
    comment: string | null = null;
}

export class CreateServiceRecordPayload {
    serviceId: number | null = null;
    startDate: Date | string | null = null;
    endDate: Date | string | null = null;
    coefficient: string | null = null;
    branchId: number | null = null;
    projectId: number | null = null;
    state: string | null = null;
    assigneeId: number | null = null;
    comment: string | null = null;
}

export class UpdateAssigneePayload {
    assigneeId: number | null = null;
    companyId: number | null = null;
    serviceId: number | null = null;
}

export interface LocationOption {
    selected(req: CreateServiceRecordPayload | UpdateServiceRecordPayload): void
}

export class BranchLocationOption implements LocationOption {
    value: number | null = null

    public selected(req: CreateServiceRecordPayload | UpdateServiceRecordPayload) {
        req.projectId = null
        req.branchId = this.value;
    }

    constructor(value: number) {
        this.value = value
    }

    public static of(value: number): BranchLocationOption {
        return new BranchLocationOption(value)
    }
}

export class ProjectLocationOption implements LocationOption {
    value: number | null = null

    selected(req: CreateServiceRecordPayload | UpdateServiceRecordPayload): void {
        req.branchId = null
        req.projectId = this.value;
    }

    constructor(value: number) {
        this.value = value
    }

    public static of(value: number): ProjectLocationOption {
        return new ProjectLocationOption(value)
    }
}

export class StateLocationOption implements LocationOption {

    value: string | null = null;

    selected(req: CreateServiceRecordPayload): void {
        req.state = this.value;
    }

    constructor(value: string) {
        this.value = value
    }

    public static of(value: string): StateLocationOption {
        return new StateLocationOption(value)
    }
}

export type ServiceInvoiceStatus = 'VOIDED' | 'PAID' | 'IN_PROGRESS'

export class ServiceInvoice {
    id!: number;
    contractor!: IEmployment;
    logs!: ServiceRecordDTO[];
    invoice!: InvoiceDTO;
    total!: number;
    status!: ServiceInvoiceStatus;
    startDate!: Date | string
    endDate!: Date | string
}

export class CreateInvoicePayload {
    contractorsId: number[] = []
    startDate: Date | string | null = null
    endDate: Date | string | null = null
}

export class UpdateInvoicePayload {
    serviceInvoiceId: number | null = null
    companyId: number | null = null
    status: ServiceInvoiceStatus | null = null

    constructor(init?: Partial<UpdateInvoicePayload>) {
        Object.assign(this, init);
    }
}

export class ServiceInvoiceFilter implements Filter{
    employerId: number | null = null;
    contractorsId: number[] = [];
    startDate: Date | string | null = null
    endDate: Date | string | null = null
    page = 0;
    size = 20;

    constructor(init?: Partial<ServiceInvoiceFilter>) {
        Object.assign(this, init);
    }
}

