






















































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import {
	BranchLocationOption,
	LocationOption, ProjectLocationOption,
	ServiceDTO, ServiceFilter, ServiceRecordDTO,
	UpdateServiceRecordPayload
} from "@/components/payroll/business/services/ServiceDTO";
import SelectOption from "@/components/common/SelectOption";
import {IEmployment} from "@/dto/payroll/IEmployment";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import {ifValid, processError} from "@/utils/ComponentUtils";
import ServiceOrchestrator from "@/services/ServiceOrchestrator";
import CompanyService from "@/services/CompanyService";
import Application from "@/state/Application";
import ServiceRecordOrchestrator from "@/services/ServiceLogOrchestrator";
import Notifications from "@/state/Notifications";
import PortalTextarea from "@/components/common/PortalTextarea.vue";

type Mode = "VIEW" | "EDIT"

@Component({
	components: {PortalTextarea, PortalDate, PortalSelect}
})
export default class EmployerUpdateServiceLogModal extends Vue {
	private selectedLocation: LocationOption | null = null;
	private locationOptions: Array<SelectOption> = [];
	private contractors: Array<IEmployment> = []
	private services: Array<ServiceDTO> = [];
	private req = new UpdateServiceRecordPayload()

	private locationMode: Mode = "VIEW"

	@Prop()
	private callback!: () => void

	@Prop()
	private serviceLog!: ServiceRecordDTO


	selected() {
		this.selectedLocation!.selected(this.req)
	}

	get customerOptions(): Array<SelectOption> {
		return this.contractors.map(c => SelectOption.builder().title(c.details.name).value(c).build())
	}

	get rxLocationOptions(): Array<SelectOption> {
		return this.locationOptions
	}


	private loadEmployments() {
		if (this.contractors.length > 0) return Promise.resolve()
		return EmploymentService.getAllByFilter(
			new EmploymentFilter(
				{
					employerId: this.$wss.getCurrent.id,
					status: [EmploymentStatus.NEW, EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS],
				}
			)
		).then(
			res => {
				if (res.data.length > 0) {
					this.req.assigneeId = res.data[0].id;
				}
				this.contractors = res.data;
			},
			err => processError(err, this)
		)
	}

	private async loadServices(): Promise<void> {
		if (!this.serviceLog.assignee) return;
		return ServiceOrchestrator.getAllByFilter(
			new ServiceFilter(
				{
					companyId: this.serviceLog.assignee!.employer.id,
					active: true
				}
			)
		).then(
			res => {
				this.services = res.data;
			},
			err => processError(err, this)
		)
	}

	private loadLocations() {
		if (!this.serviceLog.assignee) return;
		return Promise.all(
			[
				CompanyService.getProjectsByCompanyId(this.serviceLog.assignee!.employer.id).then(
					res => {
						if (res.data.length > 0) {
							this.locationOptions.push(...res.data.filter(it => it.address).map(it =>
								SelectOption.builder()
									.title(it.title!)
									.value(ProjectLocationOption.of(it.id!))
									.build())
							)
						}
					}
				),
				CompanyService.getBranches(this.serviceLog.assignee!.employer.id).then(
					res => {
						if (res.data.length > 0) {
							this.locationOptions.push(...res.data.filter(it => it.address).map(it =>
								SelectOption.builder()
									.title(it.title!)
									.value(BranchLocationOption.of(it.id!))
									.build())
							)
						}
					}
				),
				CompanyService.getHeadQuarter(this.serviceLog.assignee!.employer.id).then(
					res => {
						if (res.data) {
							this.locationOptions.push(
								SelectOption.builder()
									.title(res.data.title!)
									.value(BranchLocationOption.of(res.data.id!))
									.build()
							)
						}
					}
				)
			]
		).then(
			() => {return;},
			err => processError(err, this)
		)
	}

	private loadData() {

		this.req.serviceId = this.serviceLog.id
		this.req.assigneeId = this.serviceLog.assignee.id
		this.req.comment = this.serviceLog.comment
		this.req.startDate = this.serviceLog.startDate
		this.req.endDate = this.serviceLog.endDate
		Application.startLoading();
		this.loadServices()
			.then(() => this.loadLocations())
			.finally(() => Application.stopLoading())
	}

	mounted() {
		this.loadData()
	}

	update() {
		ifValid(this, () => {
			Application.startLoading()
			ServiceRecordOrchestrator.updateLog(this.req).then(
				() => {
					this.$modal.hideAll()
					Application.stopLoading()
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

	save() {
		Application.startLoading()
		ServiceRecordOrchestrator.updateLog(this.req).then(
			() => {
				Notifications.success("Data has been successfully updated!")
				this.$modal.hideAll()
				Application.stopLoading()
				this.callback()
			},
			err => processError(err, this)
		)
	}
}
