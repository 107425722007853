










































































































import {Component, Vue} from 'vue-property-decorator';
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import Page, {EMPTY_PAGE} from "@/dto/taxation/Page";
import {
	ServiceInvoice,
	ServiceInvoiceFilter,
	ServiceRecordDTO,
	ServiceRecordFilter
} from "@/components/payroll/business/services/ServiceDTO";
import {IEmployment} from "@/dto/payroll/IEmployment";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import ServiceRecordOrchestrator from "@/services/ServiceLogOrchestrator";
import CreationEmployerServiceLogModal
	from "@/components/payroll/business/services/CreationEmployerServiceLogModal.vue";
import TimeSheetDetails from "@/components/payroll/timesheet/TimeSheetDetails.vue";
import ServiceLogView from "@/components/payroll/business/services/ServiceLogView.vue";
import CreationServiceInvoiceModal from "@/components/payroll/business/services/CreationServiceInvoiceModal.vue";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import {subSrtIfNeed} from "../../../../utils/StringUtils";
import ServiceInvoiceOrchestrator from "@/services/ServiceInvoiceOrchestrator";
import ServiceInvoiceItem from "@/components/payroll/business/services/ServiceInvoiceItem.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

@Component({
	methods: {subSrtIfNeed},
	components: {ServiceInvoiceItem, ServiceLogView, TimeSheetDetails, PaginationComponent}
})
export default class EmployerServiceLogList extends Vue {
	private records: Page<ServiceRecordDTO> = EMPTY_PAGE;
	private contracts: Array<IEmployment> = []
	private invoices: Page<ServiceInvoice> = EMPTY_PAGE

	private filter = new ServiceRecordFilter(
		{
			employerId: this.$wss.getCurrent.id,
		}
	)

	private invoiceFilter = new ServiceInvoiceFilter(
		{
			employerId: this.$wss.getCurrent.id,
			size: 5
		}
	)


	mounted() {
		Application.startLoading()
		this.loadServiceRecords()
			.then(() => {
				return EmploymentService.getAllByFilter(
					new EmploymentFilter(
						{

							status: [EmploymentStatus.ACTIVE, EmploymentStatus.NEW, EmploymentStatus.PENDING_FOR_DOCUMENTS],
							types: ["FOREIGN", "CONTRACTOR"],
							employerId: this.$wss.getCurrent.id,
						}
					)
				).then(
					res => this.contracts = res.data,
					err => processError(err, this)
				)
			})
			.then(() => this.loadInvoices())
			.finally(() => Application.stopLoading())
	}

	private loadServiceRecords() {
		return ServiceRecordOrchestrator.getPageByFilter(this.filter).then(
			res => {this.records = res.data},
			err => processError(err, this)
		)
	}

	private loadInvoicesSeparately() {
		Application.startLoading()
		this.invoices = EMPTY_PAGE
		this.loadInvoices().finally(() => Application.stopLoading())
	}

	private showCreationModal() {
		this.$modal.show(
			CreationEmployerServiceLogModal,
			{
				callback: this.loadServiceRecords
			},
			{
				classes: ["overflow-visible"]
			}
		)
	}

	private loadNextPage(page: number) {
		this.filter.page = page
		Application.startLoading()
		this.loadServiceRecords()
			.finally(() => Application.stopLoading())
	}

	private close(record: ServiceRecordDTO) {
		(this.$refs[`popover_${record.id}`] as Array<Vue>)[0].$emit("close")
	}

	private showInvoiceCreationModal() {
		this.$modal.show(
			CreationServiceInvoiceModal,
			{
				contractors: this.contracts,
				callBack: () => {
					this.$modal.hideAll()
					Application.startLoading()
					this.invoices = EMPTY_PAGE
					this.loadInvoices().finally(Application.stopLoading)
				}
			}
		)
	}

	private loadInvoices() {
		return ServiceInvoiceOrchestrator.getPageByFilter(this.invoiceFilter).then(
			res => {
				const invs: Array<ServiceInvoice> = [...this.invoices.content, ...res.data.content]
				this.invoices = res.data
				this.invoices.content = invs
			},
			err => processError(err, this)
		)
	}

	private loadNextInvoicePage() {
		this.invoiceFilter.page++
		Application.startLoading()
		this.loadInvoices()
			.finally(() => Application.stopLoading())
	}

	private disable(record: ServiceRecordDTO) {
		this.close(record)
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => {
					Application.startLoading()
					ServiceRecordOrchestrator.disableLog(record.id, record.assignee.id).then(
						() => {
							return Promise.all(
								[
									this.loadServiceRecords(),
									this.loadInvoices()
								]
							)
						},
						err => processError(err, this)
					).finally(Application.stopLoading)
				},
				targetName: `"${record.name}"`,
				operationName: 'disable'
			}
		)
	}

	private update() {
		Application.startLoading()
		this.invoices = EMPTY_PAGE
		return Promise.all(
			[
				this.loadServiceRecords(),
				this.loadInvoices()
			]
		).finally(Application.stopLoading)
	}
}
